
import { classes } from '@/states/classes'
import { defineComponent, PropType, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Dialog, Toast } from 'vant'
import { api } from '@/api/useAxios'
import { Class, User } from 'momai'

export default defineComponent({

  props: {
    userInfo: {
      type: Object as PropType<User>
    }
  },

  setup: (props, { emit }) => {
    const checkedClasses = ref<Class[]>([])
    const { userId } = useRoute().params
    const showClassChooser = ref(false)
    const setCheckedClasss = () => {
      const classIds = props.userInfo?.userClass?.split(',')
      checkedClasses.value = classes.value.filter(c => classIds?.some(id => c.id === Number(id)))
    }
    setCheckedClasss()
    watch(() => props.userInfo, setCheckedClasss)
    watch(classes, setCheckedClasss)
    return {
      showClassChooser,
      classes,
      checkedClasses,
      setUserClass: () => {
        Dialog.confirm({
          title: '确认',
          message: '确认添加班级吗？'
        })
          .then(() => {
            api.post('/classinfo/addclass', {
              userid: userId,
              classlist: checkedClasses.value.map(item => ({
                ...item,
                checked: true
              }))
            }).then(() => {
              showClassChooser.value = false
              Toast.success('添加成功')
              emit('class-added')
            })
          })
          .catch(() => {
          })
      }
    }
  }
})
